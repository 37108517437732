import React, { useEffect, useState } from 'react'
import './OrderDetails.css'
import { Link, useParams } from 'react-router-dom'
import { load } from '@cashfreepayments/cashfree-js';


const OrderDetails = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;
    const frontend_url = process.env.REACT_APP_FRONTEND_URL;
    const cashfree_mode = process.env.REACT_APP_CASHFREE_MODE;
    const cashfreeRedirectTarget = process.env.REACT_APP_CASHFREE_REDIRECT_TARGET;

    const { orderId } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [customerDetails, setCustomerDetails] = useState(null);


    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        result = new Date(result).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        return result;
    };

    const handleMailClick = (event) => {
        event.preventDefault();
        const email = 'support@aizenstyles.in';
        const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    
        window.open(gmailComposeUrl, '_blank');
    };

    useEffect(() => {
        fetch(`${api_url}/getorder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({ oid: orderId }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success){
                setOrderDetails(data.order);
            }
        });
    },[]);

    useEffect(() => {
        if (orderDetails!=null) {
            if (productDetails.length===0){
                orderDetails.order_items.map(product => {
                    fetch(`${api_url}/getproductbypid`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key,
                            'x-client-id': client_id,
                        },
                        body: JSON.stringify({ pid: product.pid }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success){
                            setProductDetails([...productDetails, data.product]);
                            
                            fetch(`${api_url}/getcustomer`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-api-key': api_key,
                                    'x-client-id': client_id,
                                },
                                body: JSON.stringify({ cid: orderDetails.cid }),
                            })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success){
                                    setCustomerDetails(data.customer);
                                }
                            })
                        }
                    });
                });
            }
    
        }
    }, [orderDetails]);

    const retryPayment = async (oid) => {
        const cashfree = await load({
          mode: cashfree_mode,
        });
    
        fetch(`${api_url}/verifyorder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key,
            'x-client-id': client_id,
          },
          body: JSON.stringify({ orderId: oid }),
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            cashfree.checkout({
              paymentSessionId: data.payment_session_id,
              redirectTarget: cashfreeRedirectTarget,
              returnUrl: `${frontend_url}/paymentconfirmation?orderId=${oid}&paymentMode=cc,dc,nb,upi`,
        
            }).then((result) => {
            //   if(result.error){
            //       // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
            //       console.log("User has closed the popup or there is some payment error, Check for Payment Status");
            //       console.log(result.error);
            //   }
            //   if(result.redirect){
            //       // This will be true when the payment redirection page couldnt be opened in the same window
            //       // This is an exceptional case only when the page is opened inside an inAppBrowser
            //       // In this case the customer will be redirected to return url once payment is completed
            //       console.log("Payment will be redirected");
            //   }
            //   if(result.paymentDetails){
            //       // This will be called whenever the payment is completed irrespective of transaction status
            //       console.log("Payment has been completed, Check for Payment Status");
            //       console.log(result.paymentDetails.paymentMessage);
            //   }
            });
          }
        });
      };

    const handleCancelOrder = (oid) => {
        fetch(`${api_url}/cancelorder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({ oid }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                alert('Order Cancelled Successfully');
                window.location.reload();
            }
        });
    }

  return (
    <div className="order-details">
        <div className="main">
            <h3>Order Details</h3>
            {orderId && orderDetails && productDetails && customerDetails &&
            <div>
            <div className="order-info">
                <div className="note" hidden>
                    <h4 className='title'>Hey, {customerDetails.first_name} {`:)`}</h4>
                    <p className='message'>Your order is {orderDetails.order_status}.<br />You will receive an email when your order is ready.</p>
                </div>
                <div className="products">
                    <h3>Items</h3>
                    {orderDetails.order_items.map(orderItem => {
                        return productDetails.reduce((acc, product) => {
                            return acc.concat(
                                product.colors.reduce((colorAcc, color) => {
                                    return colorAcc.concat(
                                        color.sizes.reduce((sizeAcc, size) => {
                                            if (product.pid === orderItem.pid && color.name === orderItem.color && size.size === orderItem.size) {
                                                sizeAcc.push(
                                                    <div className="item" key={`${orderItem.pid}-${orderItem.color}-${orderItem.size}`}>
                                                        <div className="product-image">
                                                            <img src={color.images[0]} alt={product.name} />
                                                        </div>
                                                        <div className="product-details">
                                                            <span className='value'>
                                                                {product.name} ({orderItem.color}) ({orderItem.size}) x{orderItem.quantity}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return sizeAcc;
                                        }, [])
                                    );
                                }, [])
                            );
                        }, []);
                    })}

                    {/* <span className="label">Product Name:</span>
                    {orderDetails.order_items.map(orderItem => {
                        return productDetails.map(product => {
                            if (product.pid === orderItem.pid) {
                                return (
                                    <span key={`${orderItem.pid}-${orderItem.color}-${orderItem.size}`} className='value'>
                                        {product.name} {`(${orderItem.color})`} {`(${orderItem.size})`} x{orderItem.quantity}
                                    </span>
                                );
                            }
                            return null;
                        });
                    })} */}
                </div>
                <div className="details">
                    <h3>Order Details</h3>
                    <div className="row order-id">
                        <span className="label">Order ID.:</span>
                        <span className="value">#{orderId}</span>
                    </div>
                    <div className="row order-status">
                        <span className='label'>Order Status:</span>
                        <span className='value'>{orderDetails.order_status}</span>
                    </div>
                    <div className="row payment-mode">
                        <span className="label">Payment Mode:</span>
                        <span className='value'>{orderDetails.payment_mode==="cc,dc" ? "card" : orderDetails.payment_mode==="nb" ? 'net banking' : orderDetails.payment_mode}</span>
                    </div>
                    <div className="row payment-status">
                        <span className='label'>Payment Status:</span>
                        <span className="value">{orderDetails.payment_status}</span>
                    </div>
                    <div className="row order-date">
                        <span className="label">Order date:</span>
                        <span className='value'>
                            {new Date(orderDetails.order_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </span>
                    </div>
                    <div className="row delivery-date">
                        {orderDetails.date_delivered ? 
                            <>
                            <span className="label">Delivery Date:</span><span className='value'>
                                {`${addDays(orderDetails.date_delivered, 0)}`}
                            </span></> : <><span className="label">Est. Delivery Date:</span><span className='value'>
                                {`${addDays(orderDetails.order_date, 7)}`}
                            </span></>
                        }
                    </div>
                    <div className="row tracking-id">
                        <span className="label">Tracking ID</span>
                        <span className='value'>{orderDetails.tracking_id != '' ? orderDetails.tracking_id : 'NA'}</span>
                    </div>
                    <div className="row tracking-url">
                        <span className="label">Tracking Link</span>
                        {orderDetails.tracking_url === '' ? <span className='value'>NA</span> : <Link to={orderDetails.tracking_url} target='_blank' className='value'>Track here</Link>}
                    </div>
                    <div className="row order-total">
                        <span className="label">Order Total:</span>
                        <span className='value'>Rs. {orderDetails.order_total}</span>
                    </div>
                </div>
                <div className="address">
                    <h3>Shipping / Billing Address</h3>
                    <div className="shipping-address">
                        <span className="fullname">{orderDetails.shipping_address.first_name} {orderDetails.shipping_address.last_name}</span><br />
                        <span className="phone">{orderDetails.shipping_address.phone}</span><br />
                        <span className="address-line">{orderDetails.shipping_address.building}, {orderDetails.shipping_address.area}</span><br />
                        <span className="landmark">{orderDetails.shipping_address.landmark}</span><br />
                        <span className="pincode">{orderDetails.shipping_address.pincode}</span><br />
                        <span className="city">{orderDetails.shipping_address.city}</span><br />
                        <span className="state">{orderDetails.shipping_address.state}</span><br />
                        <span className="country">{orderDetails.shipping_address.country}</span><br />
                    </div>
                </div>
            </div>

            <p><span className="bold">NOTE:</span> Mail us at <Link onClick={handleMailClick}>aizen.renex@gmail.com</Link> for returns and other assistance.</p> 
            <div className="cta">
                {
                    orderDetails.payment_status !== 'processing' && orderDetails.payment_status !== 'processed' && orderDetails.payment_status !== 'expired' && orderDetails.order_status !== 'cancelled' && orderDetails.payment_mode !== 'cod' ?
                    <div className="btn pay" onClick={() => retryPayment(orderDetails.oid)}>Retry Payment</div> : null
                }
                {
                    orderDetails.order_status === 'delivered' ? 
                    <div className="btn return">Return Order</div> : null 
                }
                {
                    orderDetails.order_status === 'confirmed' || orderDetails.order_status==='pending' ?
                    <div className="btn cancel" onClick={()=>handleCancelOrder(orderDetails.oid)}>Cancel</div> : null
                }
                {
                    // orderDetails.order_status === 'cancelled' ? 
                    // <div className="btn reorder">Reorder</div> : null 
                }
            </div>
            </div>
            }
        </div>
    </div>
  )
}

export default OrderDetails