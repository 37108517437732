import React, { useEffect, useState } from 'react'
import './css/Register.css'
import { Link } from 'react-router-dom'

const Register = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    window.scrollTo(0, 0);
    useEffect(() => {
        document.title = 'Register - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Create an account at Aizen Styles to access your orders, discover products, and more.");
    }, []);

    const [message, setMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [confirmPassMessage, setConfirmPassMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [formData,setFormData] = useState({first_name:"",last_name:"",email:"",phone:"",password:"", cartData: localStorage.getItem('cartItems') ? localStorage.getItem('cartItems') : []});

    useEffect(() => {
        console.log("hh", formData);
    }, [formData]);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        
        if (name === 'phone') {
            // This will only allow numbers to be entered in the phone number field
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const formValidation = () => {
        if (formData.first_name === '' || formData.last_name === '' || formData.email === '' || formData.phone === '' || formData.password === '', confirmPassword === '') {
            setMessage("Please fill in all fields.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
            return false;
        }
        else {
            const message = document.querySelector('.message');
            message.classList.add('hide');
        }

        // email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(formData.email)) {
            setEmailMessage("Please enter a valid email address.");
            const emailError = document.querySelector('.emailMessage');
            emailError.classList.remove('hide');
            return false;
        }
        else {
            const emailError = document.querySelector('.emailMessage');
            emailError.classList.add('hide');
        }

        if (formData.password !== confirmPassword) {
            setConfirmPassMessage("Passwords do not match.");
            console.log(confirmPassMessage);
            const confirmPassError = document.querySelector('.confirmPassMessage');
            confirmPassError.classList.remove('hide');
            return false;
        }


        return true;
    }

    const handleRegister = async () => {
        if (!formValidation()) {
            return;
        }
        try {
            let dataObj;
            console.log(formData);
            await fetch(`${api_url}/register`, {
                method: 'POST',
                headers: {
                    Accept:'application/form-data',
                    'Content-Type':'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {dataObj = data});
            
            if (dataObj.success) {
                localStorage.setItem('auth-token',dataObj.token);
                window.location.replace("/");
                }
            else{
                setMessage(dataObj.errors);
                const message = document.querySelector('.message');
                message.classList.remove('hide');
            }

        } catch (error) {
            setMessage("Something went wrong. Please try again later.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
        }
    }

  return (
    <div className='section-register'>
        <div className="register-form">
            <h1 className="section-title">Create Your Account</h1>
            <div className="message hide">
                <p>{message}</p>
            </div>
            <div className="form">
                <div className="form-group name">
                    <div className="firstname">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text" name="first_name" id="first_name" value={formData.first_name} onChange={changeHandler} required/>
                    </div>
                    <div className="lastname">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text" name="last_name" id="last_name" value={formData.last_name} onChange={changeHandler} required/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" value={formData.email} onChange={changeHandler} required/>
                    <div className="emailMessage input-message hide">
                        <p>{emailMessage}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="text" name="phone" id="phone" value={formData.phone} onChange={changeHandler} required/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" value={formData.password} onChange={changeHandler} required/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" name="confirm-password" id="confirm-password" value={confirmPassword} onChange={handleConfirmPassword} required/>
                    <div className="confirmPassMessage input-message hide">
                        <p>{confirmPassMessage}</p>
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn" onClick={handleRegister}>Register</button>
                </div>
            </div>
            <div className="go-to-login">
                <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
        </div>
    </div>
  )
}

export default Register
