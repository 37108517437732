import React, { useEffect, useState } from 'react'
import './css/Login.css'
import { Link } from 'react-router-dom'

const Login = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    window.scrollTo(0, 0);
    useEffect(() => {
        document.title = 'Login - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Login to your Aizen Styles account to access your orders, discover products, and more.");
    }, []);

    const [message, setMessage] = useState('');

    const [formData,setFormData] = useState({email:"",password:""});

    const changeHandler = (e) => {
        setFormData({...formData,[e.target.name]:e.target.value});
    }

    const handleLogin = async () => {
        let dataObj;

        try {
            await fetch(`${api_url}/login`, {
                method: 'POST',
                headers: {
                    Accept:'application/form-data',
                    'Content-Type':'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {dataObj = data});
            
            if (dataObj.success) {
                localStorage.setItem('auth-token',dataObj.token);
                window.location.replace("/");
              }
            else{
                setMessage(dataObj.errors);
                const message = document.querySelector('.message');
                message.classList.remove('hide');
            }

        } catch (error) {
            setMessage("Something went wrong. Please try again later.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
        }

    }
  return (
    <div className='section-login'>
        <div className="login-form">
            <h1 className="section-title">Log In</h1>
            <div className="message hide">
                <p>{message}</p>
            </div>
            <div action="" className="form">
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" onChange={changeHandler} value={formData.email}/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" onChange={changeHandler} value={formData.password}/>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn" onClick={handleLogin}>Login</button>
                </div>
            </div>
            <div className="go-to-register">
                <p>Don't have an account? <Link to="/register">Register</Link></p>
            </div>
        </div>
    </div>
  )
}

export default Login
